<template>
  <div>
    <v-col class="mx-2 my-2  ">
      <v-card>
        <v-row class="px-2 py-2">
          <v-spacer />

          <v-col
            cols="12"
            sm="4"
            md="3"
            lg="2"
            xl="2"
            class="  py-0 my-0 mb-2"
          >
            <v-menu
              ref="menu"
              v-model="menu"
              :close-on-content-click="false"
              :return-value.sync="startDate"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{on, attrs}">
                <v-text-field
                  v-model="startDate"
                  label="Tarih"
                  prepend-icon="mdi-calendar"
                  readonly
                  outlined
                  dense
                  v-bind="attrs"
                  hide-details
                  v-on="on"
                />
              </template>
              <v-date-picker
                v-model="startDate"
                no-title
                scrollable
              >
                <v-spacer />
                <Button
                  :text="true"
                  color="primary"
                  @click="menu = false"
                >
                  Kapat
                </Button>
                <Button
                  :text="true"
                  color="primary"
                  @click="$refs.menu.save(startDate)"
                >
                  Seç
                </Button>
              </v-date-picker>
            </v-menu>
          </v-col>

          <v-col
            cols="12"
            sm="5"
            md="3"
            lg="2"
            xl="2"
            class="  py-0 my-0 mb-2"
          >
            <MultiSelect
              v-model="santralModel"
              :items="santralList"
              :label="$t('santral')"
              outlined
              dense
            />
          </v-col>
          <v-col
            cols="12"
            sm="5"
            md="3"
            lg="3"
            xl="2"
            class="  py-0 my-0  mb-2"
          >
            <Select
              v-model="measureSelect"
              :items="measures"
              label=""
              hide-details
              outlined
              dense
            />
          </v-col>

          <v-col
            cols="6"
            sm="2"
            md="2"
            lg="1"
            xl="1"
            class="  py-0 my-0  mb-2"
          >
            <Button
              color="primary"
              @click="startFunc"
            >
              Filtrele
            </Button>
          </v-col>
        </v-row>

        <v-col
          v-if="santralModel.length > 0 && Object.keys(santralInverterData).length > 0"
          cols="12"
        >
          <Card
            v-for="santral in santralModel"
            :key="'d3jstotalinverter' + santral"
          >
            <ItemInverterHeatmap
              v-if="'d3jstotalinverter' + santral &&
                santralInverterData[santral] !== undefined &&
                santralData[santral] !== undefined"
              :inverterdata="santralInverterData[santral]"
              :santraldata="santralData[santral]"
              :payload="payload"
              :measures="measures"
              :query="query"
              class="mt-4"
            />
          </Card>
        </v-col>
      </v-card>
    </v-col>
  </div>
</template>

<script>

// eslint-disable-next-line import/no-unresolved
import moment from 'moment';
import ItemInverterHeatmap from '@/views/components/Inverter/ItemInverter/ItemInverterHeatmap.vue';
import helper from '@/api/helper';

export default {
  components: {
    ItemInverterHeatmap,
  },
  data() {
    return {
      measureList: [],
      santral_items: [],
      startDate: moment().format('YYYY-MM-DD'),
      finishDate: moment().format('YYYY-MM-DD'),
      menu: false,
      modal: false,
      menu2: false,
      measureSelect: 'ACPower',
      componentKey: 1,
      title: '',

      inverterList: [],
      santralModel: [],

      chartId: '',
      HeatmapData: [],
      inverterdata: {},
      svgclass: {
        'overflow-x': 'auto',
        width: '99%',
      },

      query: false,
    };
  },
  computed: {
    measures() {
      const { inverterMeasureList } = this.$store.getters;
      if (!helper.is_Empty(inverterMeasureList)) {
        return inverterMeasureList;
      }
      return [];
    },
    santralDataList() {
      return this.$store.getters.santralDataList;
    },
    santralList() {
      const { santralRawData } = this.$store.getters;
      if (helper.is_Empty(santralRawData)) return;
      const santralList = [];
      santralRawData.forEach((item) => {
        santralList.push({
          text: item.santral,
          value: item.santralCode,
        });
      });

      // eslint-disable-next-line consistent-return
      return santralList;
    },
    santralInverterData() {
      const { santralInverterData } = this.$store.getters;
      if (helper.is_Empty(santralInverterData) && Object.keys(santralInverterData).length === 0) return;
      // eslint-disable-next-line consistent-return
      return santralInverterData;
    },
    santralData() {
      const { santralRawData } = this.$store.getters;
      if (helper.is_Empty(santralRawData)) return;
      const santralData = {};
      santralRawData.forEach((item) => {
        santralData[item.santralCode] = item;
      });
      // eslint-disable-next-line consistent-return
      return santralData;
    },
  },
  watch: {
    santralModel() {
      this.startFunc();
    },
    santralList() {
      const santralModel = [];
      if (this.santralList.length > 0) {
        this.santralList.forEach((element) => {
          santralModel.push(element.value);
        });
        this.santralModel = santralModel;
      }
    },
  },
  mounted() {
    this.payload = {
      startDate: this.startDate,
      finishDate: this.startDate,
      measures: this.measureSelect,
    };
    // eslint-disable-next-line consistent-return
  },
  methods: {
    startFunc() {
      if (!helper.is_Empty(this.santralInverterData)) {
        this.payload = {
          startDate: this.startDate,
          finishDate: this.startDate,
          measures: this.measureSelect,
        };
        this.query = !this.query;
        Object.keys(this.santralInverterData).forEach((santralCode) => {
          this.chartId = `d3jstotalinverter${santralCode}`;
          const elemnet = document.getElementById(this.chartId);
          if (elemnet !== null) {
            elemnet.innerHTML = '';
          }
        });
      }
    },

  },
};
</script>
