<template>
  <div
    :id="'d3jstotalinverter' + santraldata.santralCode"
  />
</template>

<script>

// eslint-disable-next-line import/no-unresolved
import helper from '@/api/helper';
import { inverterMinuteDataHeatmap } from '@/api/Inverter/InverterHeatmap';

export default {
  components: {},
  // eslint-disable-next-line vue/require-prop-types
  props: ['inverterdata', 'santraldata', 'payload', 'measures', 'query'],
  data() {
    return {
      chartId: '',
      HeatmapData: [],

      svgclass: {
        'overflow-x': 'auto',
        width: '99%',
      },
      heightVal: {},
    };
  },
  computed: {},
  watch: {
    inverterdata() {
      this.startFunc();
    },
    query() {
      this.startFunc();
    },
  },
  mounted() {
    if (!helper.is_Empty(this.inverterdata)) {
      this.startFunc();
    }
  },
  methods: {

    startFunc() {
      this.chartId = `d3jstotalinverter${this.santraldata.santralCode}`;

      const elemnet = document.getElementById(this.chartId);
      if (elemnet !== null) {
        elemnet.innerHTML = '';
      }
      inverterMinuteDataHeatmap(this.measures, this.inverterdata, this.santraldata, this.payload, this.chartId);
    },
  },
};
</script>
