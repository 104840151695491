import * as Heatmap from '@/api/Inverter/Heatmap';
import ApexCharts from 'apexcharts';

// eslint-disable-next-line import/prefer-default-export
export function inverterMinuteDataHeatmap(measures, inverterdata, santraldata, payload, chartId) {
  const inverterList = Object.keys(inverterdata);

  const formdata = {
    condiniton: {
      santral: santraldata.santral,
      startDate: payload.startDate,
      finishDate: payload.startDate,
      measures: payload.measures,
      INVERTER: inverterList,
      maxcount: 3000,
      MinuteGrup: [0, 10, 20, 30, 40, 50],
    },
  };
  if (inverterdata.prefix) {
    formdata.condiniton.prefix = inverterdata.prefix;
    formdata.condiniton.companyCode = inverterdata.companyCode;
  }

  const temp = Heatmap.default.inverterMinuteDataHeatmap(
    formdata,
    inverterdata,
    payload.measures,
  );
  temp.then((data) => {
    const elemnet = document.getElementById(chartId);
    if (elemnet !== null) {
      elemnet.innerHTML = '';
    }
    const margin = {
      top: 10,
      right: 150,
      bottom: 0,
      left: 150,
    };
    let itemlenght = 5;
    if (inverterList.length > 0) {
      itemlenght = inverterList.length;
    }

    let height = itemlenght * 25;
    if (height < 300) {
      height += 100;
    }

    let unit = '';
    measures.forEach((measure) => {
      if (payload.measures === measure.value) {
        unit = measure.unit;
      }
    });
    const parameter = {

      height: height + 10,
      transform: `translate(${margin.left},${margin.top})`,
      chartId,
      chartXkey: 'time',
      chartYkey: 'INVERTERNAME',
      measureSelect: payload.measures,
      unit,
      startDate: payload.startDate,
    };

    // eslint-disable-next-line no-use-before-define
    ApexChartsSetting(data, height, inverterdata, chartId, parameter, santraldata, payload);
  });
}

function ApexChartsSetting(data, height, inverterdata, chartId, parameter, santraldata, payload) {
  const elemnet = document.getElementById(chartId);
  if (elemnet !== null) {
    elemnet.innerHTML = '';
  }
  const options1 = {
    series: [],
    chart: {
      height,
      type: 'heatmap',
    },
    dataLabels: {
      enabled: false,
    },
    chartInfo: parameter,
    plotOptions: {
      show: false,
      heatmap: {
        colorScale: {
          ranges: [
            {
              from: 0,
              to: 1,
              color: '#f03a2e',
            },
            {
              from: 2,
              to: 2,
              color: '#FF8F00',
            },
            {
              from: 3,
              to: 3,
              color: '#32993b',
            },
          ],
        },
      },
    },
    stroke: {
      show: true,
      colors: ['#fff'],
      width: 1,
    },
    xaxis: {
      type: 'category',
    },
    title: {
      text: `${santraldata.santral} santrali ${payload.measures} karşılaştırması`,
    },
    tooltip: {
      custom({ seriesIndex, dataPointIndex, w }) {
        return `${'<div style="padding: 10px; color: #000; background-color: #fff;">'
          + '<span>'}Saat : ${w.globals.labels[dataPointIndex]}</span><br/>`
          + `<span> Cihaz :${w.config.series[seriesIndex].name}</span> <br/>`
          + `<span> ${w.config.chartInfo.measureSelect}: ${w.config.series[seriesIndex].data[dataPointIndex].value.toFixed(1)} ${w.config.chartInfo.unit}</span><br/> `
          + `<span> Ortalama: ${w.config.series[seriesIndex].data[dataPointIndex].mean.toFixed(1)} ${w.config.chartInfo.unit}</span>`
          + '</div>';
      },
    },
  };

  Object.keys(inverterdata).forEach((i) => {
    const obj = {};

    obj.name = inverterdata[i].INVERTERNAME;
    // eslint-disable-next-line no-use-before-define
    obj.data = generateData(inverterdata[i], data);
    options1.series.push(obj);
  });
  const k = `#${chartId}`;

  if (elemnet !== null) {
    const chart = new ApexCharts(document.querySelector(k), options1);
    chart.render();
  }
}

function getCellColor(item) {
  const yuzde = item.meanval;
  if (item.value > 0 && item.value < 1) {
    return 1;
  } if (item.value > 1 && item.value < yuzde) {
    // eslint-disable-next-line no-mixed-operators
    return 2;
  }
  if (item.value > yuzde) {
    // eslint-disable-next-line no-mixed-operators
    return 3;
  }
  return 1;
}
// eslint-disable-next-line no-shadow
function generateData(inv, data) {
  const generatedData = [];

  data.forEach((item) => {
    const val = getCellColor(item);
    if (inv.INVERTERNAME === item.INVERTERNAME) {
      generatedData.push({
        x: item.time,
        y: val,
        meanval: item.meanval,
        mean: item.mean,
        value: item.value,
      });
    }
  });

  return generatedData;
}
